<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.lecturers')"
                :pgIcon="'bx bxs-user-rectangle'"
                :refs="refs"
                :addNew="addNew">
            </Breadcrumb>


            <DataTable
                :refs="refs"
                :colspan=7

                :hasSearchFilter=true
                :hasDateFilter=true
                :hasStatusFilter=true

                :hasHashId=true
                :hasMultiSelect=false
                :hasBulkAction=false
                :HasExport=true
                :hasShowEntries=true

                :lblSearch="$t('app.search_by_name_or_email_or_phone')"

                :hasImage=true
                :image="$t('view.lecturer_image')"
                :image_style="'width: 40px;height: 40px;border-radius: 50%'"
                :hasTitle=true
                :title="$t('view.lecturer_name')"
                :hasPosition=true
                :position="$t('view.lecturer_position')"
                
                :hasDate=true
                :hasStatus=true
                :hasActions=true
                :hasDeleteButton=true

                :actionable=true
                :sorDataTable=true

                v-on:hasAddNewPermission="handlePermissoinChange">
            </DataTable>

        </div>
    </div>

</template>

<script>
export default {
    name: 'List',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        DataTable: () => import('@/components/DataTable.vue')
    },
    data(){
        return {    
            //
            addNew: false,
            refs: 'lecturers',
        }
    },
    watch: {
        //
    },
    mounted() {
        //
    },
    created() {
        //
    },
    methods: {
        handlePermissoinChange(event) {
            this.addNew = event;
        },
    },
}
</script>
